<template>
  <div>
    <el-tabs v-model="printType" type="border-card">
      <el-tab-pane label="控件打印" name="control">
        <div style="margin-bottom: 20px">
          指定打印机：
          <el-select v-model="printId">
            <template v-for="item in printList">
              <el-option
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </template>
          </el-select>
        </div>
        <div class="table">
          <el-table
            v-loading="loading || prewLoading"
            :data="printListData.list"
            stripe
            height="300"
          >
            <template v-for="item in printRowList">
              <el-table-column
                :key="item.field_alias"
                align="center"
                :prop="item.field_alias"
                :label="item.field_text"
                :width="item.t_width"
              >
              </el-table-column>
            </template>
            <el-table-column align="center" prop="action" label="操作">
              <template slot-scope="scope">

                 <el-button
                  type="text"
                  @click="handleSet(scope.row.print_template_local_id,scope.row)">设置</el-button>

                <el-button
                  type="text"
                  @click="handlePreView2(scope.row.print_template_local_id)"
                  >预览</el-button
                >
                <!-- <el-button
              type="text"
              v-print="'custom' + scope.row.index"
              :disabled="htmlNodeObj[scope.row.index] ? false : true"
              >打印</el-button
            > -->
                <el-button
                  type="text"
                  @click="handlePrint2(scope.row.print_template_local_id)"
                  >直接打印</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagina">
          <el-pagination
            background
            :current-page="page"
            @current-change="handleCurrentChange"
            :page-size="limit"
            layout="total, prev, pager, next, jumper"
            :total="printListData.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="非控件打印" name="no_control">
        <no-control :type="type" :order_id="order_id" :print_bill_type="print_bill_type" :goods_number_ids="goods_number_ids" />
      </el-tab-pane>
    </el-tabs>
    <div style="overflow: hidden; position: relative;">
      <!-- 预览 -->
      <div id="customPrint" v-html="dom" style="position: absolute; left: 9999px;"></div>
      <div id="base64" style="position: absolute; left: 9999px;">
        <img :src="base64" />
      </div>
      <!-- 直接打印 -->
      <div v-for="(item, index) in content" :key="index" :id="'customPrint' + index" v-html="item" style="position: absolute; left: 9999px;"></div>
      <div id="base64_list" style="position: absolute; left: 9999px;">
        <img v-for="(item, index) in base64_list" :key="'img' + index" :src="item" :id="'base_img' + index" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCustomTemplateListReq as getPrintListReq,
  getTemplateContentReq,
} from "@/api/system/print/customPrint";
import NoControl from './NoControl.vue';
import html2canvas from 'html2canvas';

export default {
  name: "Print",
  props: {
    type: Number,
    order_id: Number | String,
    print_bill_type: String,
    goods_number_ids: Array,
  },
  data() {
    return {
      printContent: [
        [
          {
            field_alias: 'goods_number',
            left: 10,
            top: 10,
            width: 20,
            height: 100,
            type: 'text',
            value: '钻石项链',
            fontSize: '6',
            textAlign: 'center',
            positionX: 10,
            positionY: 10,
          },
          {
            field_alias: 'goods_number',
            left: 10,
            top: 10,
            width: 20,
            height: 20,
            type: 'custom_text',
            value: '自定义文字1自定义文字1自定义文字1自定义文字1自定义文字1自定义文字1自定义文字1自定义文字1',
            fontSize: '8',
            textAlign: 'center',
            positionX: 20,
            positionY: 20,
          },
          {
            field_alias: 'goods_number',
            left: 10,
            top: 10,
            width: 30,
            height: 10,
            type: 'code',
            value: '12345678',
            fontSize: '12',
            textAlign: 'center',
            positionX: 35,
            positionY: 35,
          },
          {
            field_alias: 'goods_number',
            left: 10,
            top: 10,
            width: 20,
            height: 100,
            type: 'table',
            value: '12345678',
            fontSize: '12',
            textAlign: 'center',
            positionX: 40,
            positionY: 40,
            html: '',
          },
        ],
        [
          {
            field_alias: 'goods_number',
            left: 10,
            top: 10,
            width: 20,
            height: 100,
            type: 'text',
            value: '钻石项链',
            fontSize: '6',
            textAlign: 'center',
            positionX: 10,
            positionY: 10,
          },
          {
            field_alias: 'goods_number',
            left: 10,
            top: 10,
            width: 20,
            height: 100,
            type: 'custom_text',
            value: '自定义文字',
            fontSize: '8',
            textAlign: 'center',
            positionX: 20,
            positionY: 20,
          },
          {
            field_alias: 'goods_number',
            left: 10,
            top: 10,
            width: 30,
            height: 10,
            type: 'code',
            value: '12345678',
            fontSize: '12',
            textAlign: 'center',
            positionX: 35,
            positionY: 35,
          },
          {
            field_alias: 'goods_number',
            left: 10,
            top: 10,
            width: 20,
            height: 100,
            type: 'table',
            value: '12345678',
            fontSize: '12',
            textAlign: 'center',
            positionX: 40,
            positionY: 40,
            html: '',
          },
        ],
      ],
      base64_list: [],
      content: [], // 下载时的合集
      base64: '', // 转换成base64
      dom: '', // dom字符串
      prewLoading: false,
      printType: "control",
      printList: [{ name: "默认打印机", value: -1 }],
      printId: -1,
      page_size: 10, // 10张纸为一个打印任务
      loading: false,
      page: 1,
      limit: 10,
      printListData: {
        list: [],
        total: 0,
      },
      htmlNodeObj: {},
      printRowList: [
        { field_text: "模板名称", field_alias: "template_name" },
        { field_text: "模板类型", field_alias: "type_name" },
        // { field_text: "修改人", field_alias: "index2" },
        // { field_text: "修改时间", field_alias: "index3" },
        { field_text: "创建人", field_alias: "store_user_name" },
        { field_text: "创建时间", field_alias: "create_time" },
      ],
    };
  },
  components: {
    NoControl
  },
  created() {
    this.getPrinter();
    this.getPrintList();
    console.log('更新时间2021年10月28日19:24:24')
  },
  methods: {
    // 获取打印机
    getPrinter() {
      const LODOP = getLodop();
      if (LODOP) {
        const iPrinterCount = LODOP.GET_PRINTER_COUNT();
        for (var i = 0; i < iPrinterCount; i++) {
          let obj = {};
          obj.name = LODOP.GET_PRINTER_NAME(i);
          obj.value = i;
          this.printList.push(obj);
        }
      }
    },
    // 是否安装了控件
    CheckIsInstall(callback) {
      const LODOP = getLodop();
      if (LODOP) {
        callback();
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          message:
            'Web打印服务CLodp未安装启动，点击这里<a href="http://www.lodop.net/download.html" target="_blank">下载执行安装</a>，成功后请刷新本页面。',
          showClose: true,
          type: "warning",
        });
      }
    },
    handlePrint2(print_template_local_id) {
      this.prewLoading = true;
      this.CheckIsInstall(() => {
        // 请求模板
        getTemplateContentReq({
          order_id: this.order_id,
          print_template_local_id,
          print_bill_type: this.print_bill_type,
          goods_number_ids: this.goods_number_ids,
          page_size: this.page_size,
        }).then((res) => {
          if (res.code === 1) {
            this.prewLoading = false;
            LODOP = getLodop();
            LODOP.PRINT_INITA(
              (res.data.offset || 0) + 'mm',
              0,
              Number(res.data.width)  + "mm",
              Number(res.data.height)  + "mm",
              "控件打印"
            );
            let m = 0;
            // if (res.data.type !== 50) {
            //   m = 4;
            // }
            LODOP.SET_PRINT_PAGESIZE(
              0,
              Number(res.data.width) + "mm",
              Number(res.data.height) + "mm",
              "纸张"
            );
            LODOP.SET_PRINTER_INDEX(this.printId);
            // LODOP.SET_PRINT_STYLE("FontName", '微软雅黑');
            res.data.content.forEach((list) => {
              LODOP.NewPage();
              list.forEach((item) => {
                if (item.type === 'text' || item.type === 'custom_text') {
                  LODOP.ADD_PRINT_TEXT(item.positionY, item.positionX, item.width + 'mm', item.height + 'mm', item.value);
                  LODOP.SET_PRINT_STYLEA(0,"FontSize", Math.round(item.fontSize / 1.3));
                  LODOP.SET_PRINT_STYLEA(0,"Alignment", item.textAlign === 'left' ? 1 : (item.textAlign === 'center' ? 2 : (item.textAlign === 'right' ? 3 : '')));
                  LODOP.SET_PRINT_STYLEA(0,"Angle", -item.rotate); // 旋转
                  LODOP.SET_PRINT_STYLEA(0,"FontName", item.fontFamily); // 字体
                  LODOP.SET_PRINT_STYLEA(0,"Bold", item.bold); // 字重
                }
                if (item.type === 'code') {
                  // 一维码旋转的时候 如果角度是90 270 需要将宽高互换
                  if (item.rotate === '90' || item.rotate === '270') {
                    LODOP.ADD_PRINT_BARCODE(Number(item.positionY) + Number(item.px_height / 2) - Number(item.px_width / 2), Number(item.positionX) + Number(item.px_width / 2) - Number(item.px_height / 2), item.height + 'mm', item.width + 'mm',"128Auto", item.value);
                  } else {
                    if(item.textAlign == 'center') {
                      LODOP.ADD_PRINT_BARCODE(item.positionY,item.positionX,res.data.width-4 + 'mm', item.height + 'mm', "128Auto", item.value);
                    }else{
                      LODOP.ADD_PRINT_BARCODE(item.positionY,item.positionX, item.width + 'mm', item.height + 'mm', "128Auto", item.value);
                    }
                  }
                  // 是否显示一维码底下的数字
                  if (item.showNumber) {
                    LODOP.SET_PRINT_STYLEA(0,"FontSize",5);
                  } else {
                    LODOP.SET_PRINT_STYLEA(0,"ShowBarText",0);
                  }
                  LODOP.SET_PRINT_STYLEA(0,"Angle", -item.rotate); // 旋转
                  // if(item.textAlign == 'center'){
                  //   LODOP.SET_PRINT_STYLEA(0,"HOrient", item.textAlign === 'left' ? 0 : (item.textAlign === 'center' ? 2 : (item.textAlign === 'right' ? 1 : ''))); // 条形码对齐方式
                  // }
                  LODOP.SET_PRINT_STYLEA(0,"AlignJustify",0);
                }
                 if (item.type === 'qrcode') {
                  // 二维码旋转的时候 如果角度是90 270 需要将宽高互换
                  if (item.rotate === '90' || item.rotate === '270') {
                    LODOP.ADD_PRINT_BARCODE(Number(item.positionY) + Number(item.px_height / 2) - Number(item.px_width / 2), Number(item.positionX) + Number(item.px_width / 2) - Number(item.px_height / 2), item.height + 'mm', item.width + 'mm',"QRCode", item.value);
                  } else {
                    LODOP.ADD_PRINT_BARCODE(item.positionY, item.positionX, item.width + 'mm', item.height + 'mm',"QRCode", item.value);
                    // LODOP.ADD_PRINT_BARCODE(0,0,40,40,"QRCode","12345汉字内容");
                  }
                  LODOP.SET_PRINT_STYLEA(0,"Angle", -item.rotate); // 旋转
                }
                if (item.type.indexOf('table') > -1) {
                  LODOP.ADD_PRINT_TABLE(item.positionY, item.positionX, '500mm', '500mm', item.html);
                  LODOP.SET_PRINT_STYLEA(0,"FontName", item.fontFamily); // 字体
                }
              })
            });
            LODOP.PRINT();
          }
        })
        .catch(() => {
          this.prewLoading = false;
        });
      });
    },

    handleSet(id,item){
      this.$router.push({
        name: 'customPrintList',
        params: { obj: JSON.stringify(item),type:'edit'}
      })
      this.$emit('closeDiv')
    },

    handlePreView2(print_template_local_id) {
      this.prewLoading = true;
      this.CheckIsInstall(() => {
        // 请求模板
        getTemplateContentReq({
          order_id: this.order_id,
          print_template_local_id,
          print_bill_type: this.print_bill_type,
          goods_number_ids: this.goods_number_ids,
          page_size: this.page_size,
        }).then((res) => {
          if (res.code === 1) {
            this.prewLoading = false;
            LODOP = getLodop();
            LODOP.PRINT_INITA(
              (res.data.offset || 0) + 'mm',
              0+'mm',
              Number(res.data.width)  + "mm",
              Number(res.data.height)  + "mm",
              "控件打印"
            );
            let m = 0;
            // if (res.data.type !== 50) {
            //   m = 4;
            // }
            LODOP.SET_PRINT_PAGESIZE(
              0,
              Number(res.data.width) + "mm",
              Number(res.data.height) + "mm",
              "纸张"
            );
            LODOP.SET_PRINTER_INDEX(this.printId);
            LODOP.SET_PRINT_STYLE("FontName", '微软雅黑');
            res.data.content.forEach((list) => {
              LODOP.NewPage();
              list.forEach((item) => {
                if (item.type === 'text' || item.type === 'custom_text') {
                  LODOP.ADD_PRINT_TEXT(item.positionY, item.positionX, item.width + 'mm', item.height + 'mm', item.value);
                  LODOP.SET_PRINT_STYLEA(0,"FontSize",  Math.round(item.fontSize / 1.3));
                  LODOP.SET_PRINT_STYLEA(0,"Alignment", item.textAlign === 'left' ? 1 : (item.textAlign === 'center' ? 2 : (item.textAlign === 'right' ? 3 : '')));
                  LODOP.SET_PRINT_STYLEA(0,"Angle", -item.rotate); // 旋转
                  LODOP.SET_PRINT_STYLEA(0,"FontName", item.fontFamily); // 字体
                  LODOP.SET_PRINT_STYLEA(0,"Bold", item.bold); // 字重
                }
                if (item.type === 'code') {
                  // 一维码旋转的时候 如果角度是90 270 需要将宽高互换
                  if (item.rotate === '90' || item.rotate === '270') {
                    LODOP.ADD_PRINT_BARCODE(Number(item.positionY) + Number(item.px_height / 2) - Number(item.px_width / 2), Number(item.positionX) + Number(item.px_width / 2) - Number(item.px_height / 2), item.height + 'mm', item.width + 'mm',"128Auto", item.value);
                  } else {
                    if(item.textAlign == 'center') {
                      LODOP.ADD_PRINT_BARCODE(item.positionY,item.positionX,res.data.width-4+'mm', item.height + 'mm', "128Auto", item.value);
                    }else{
                      LODOP.ADD_PRINT_BARCODE(item.positionY,item.positionX, item.width + 'mm', item.height + 'mm', "128Auto", item.value);
                    }
                  }
                  // 是否显示一维码底下的数字
                  if (item.showNumber) {
                    LODOP.SET_PRINT_STYLEA(0,"FontSize",5);
                  } else {
                    LODOP.SET_PRINT_STYLEA(0,"ShowBarText",0);
                  }
                  LODOP.SET_PRINT_STYLEA(0,"Angle", -item.rotate); // 旋转
                  // if(item.textAlign == 'center'){
                  //   LODOP.SET_PRINT_STYLEA(0,"HOrient", item.textAlign === 'left' ? 0 : (item.textAlign === 'center' ? 2 : (item.textAlign === 'right' ? 1 : ''))); // 条形码对齐方式
                  // }
                  LODOP.SET_PRINT_STYLEA(0,"AlignJustify",0);
                }
                if (item.type === 'qrcode') {
                  // 二维码旋转的时候 如果角度是90 270 需要将宽高互换
                  if (item.rotate === '90' || item.rotate === '270') {
                    LODOP.ADD_PRINT_BARCODE(Number(item.positionY) + Number(item.px_height / 2) - Number(item.px_width / 2), Number(item.positionX) + Number(item.px_width / 2) - Number(item.px_height / 2), item.height + 'mm', item.width + 'mm',"QRCode", item.value);
                  } else {
                    LODOP.ADD_PRINT_BARCODE(item.positionY, item.positionX, item.width + 'mm', item.height + 'mm',"QRCode", item.value);
                    // LODOP.ADD_PRINT_BARCODE(0,0,40,40,"QRCode","http://www.baidu.com");
                  }
                  LODOP.SET_PRINT_STYLEA(0,"Angle", -item.rotate); // 旋转
                }
                if (item.type.indexOf('table') > -1) {
                  LODOP.ADD_PRINT_TABLE(item.positionY, item.positionX, '200mm', '200mm', item.html);
                  LODOP.SET_PRINT_STYLEA(0,"FontName", item.fontFamily); // 字体
                }
              })
            });
            LODOP.PREVIEW();
          }
        })
        .catch(() => {
          this.prewLoading = false;
        });
      });
    },
    // 控件打印
    handlePrint(print_template_local_id) {
      this.prewLoading = true;
      this.CheckIsInstall(() => {
        // 请求模板
        getTemplateContentReq({
          order_id: this.order_id,
          print_template_local_id,
          print_bill_type: this.print_bill_type,
          goods_number_ids: this.goods_number_ids,
          page_size: this.page_size,
        }).then((res) => {
          if (res.code === 1) {
            this.prewLoading = false;
            LODOP = getLodop();
            LODOP.PRINT_INITA(
              0,
              0,
              Number(res.data.width) * 10 + "mm",
              Number(res.data.height) * 10 + "mm",
              "控件打印"
            );
            let m = 0;
            if (res.data.type !== 50) {
              m = 4;
            }
            LODOP.SET_PRINT_PAGESIZE(
              0,
              Number(res.data.width) * 10,
              (Number(res.data.height) + m) * 10,
              "纸张"
            );
            LODOP.SET_PRINTER_INDEX(this.printId);
            // 直接循环打印
            res.data.content.forEach((dom) => {
              LODOP.ADD_PRINT_HTML(0, 0, "100%", "100%", dom);
              // LODOP.ADD_PRINT_HTML(0, 0, "100%", "100%", '<!DOCTYPE html><html lang="en"><head><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>小字体</title></head><body><div style="font-size: 12px; transform-origin: 0 0; transform: scale(0.8); ">我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字</div><div style="font-size: 12px;">我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字</div></body></html>');
              LODOP.PRINT();
            });
          }
        })
        .catch(() => {
          this.prewLoading = false;
        });
      });
    },
    // 控件打印预览
    handlePreView(print_template_local_id) {
      this.prewLoading = true;
      this.CheckIsInstall(() => {
        // 请求模板
        getTemplateContentReq({
          order_id: this.order_id,
          print_template_local_id,
          print_bill_type: this.print_bill_type,
          goods_number_ids: this.goods_number_ids,
          page_size: this.page_size,
        }).then((res) => {
          if (res.code === 1) {
            this.prewLoading = false;
            LODOP = getLodop();
            LODOP.PRINT_INITA(
              0,
              0,
              Number(res.data.width) * 10 + "mm",
              Number(res.data.height) * 10 + "mm",
              "控件打印"
            );
            let m = 0;
            if (res.data.type !== 50) {
              m = 4;
            }
            LODOP.SET_PRINT_PAGESIZE(
              0,
              Number(res.data.width) * 10,
              (Number(res.data.height) + m) * 10,
              "纸张"
            );
            let dom = "";
            res.data.content.forEach((str) => {
              dom += str;
            });
            this.dom = dom;
            this.$nextTick(() => {
              LODOP.ADD_PRINT_HTML(0, 0, "100%", "100%", document.getElementById('customPrint').innerHTML);
              // LODOP.ADD_PRINT_URL(0, 0, "100%", "100%", 'https://fx.etouch.vip/');
              // LODOP.ADD_PRINT_HTML(0, 0, "100%", "100%", '<!DOCTYPE html><html lang="en"><head><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>小字体</title></head><body><div style="font-size: 12px; transform-origin: 0 0; transform: scale(0.8); ">我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字我是小字体的字</div><div style="font-size: 12px;">我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字我是正常的字</div></body></html>');
              // LODOP.SET_SHOW_MODE("HIDE_PBUTTIN_PREVIEW", 1);
              // LODOP.SET_SHOW_MODE("HIDE_SBUTTIN_PREVIEW", 1);
              LODOP.SET_PRINTER_INDEX(this.printId);
              LODOP.PREVIEW();
            })

          }
        })
        .catch(() => {
          this.prewLoading = false;
        });
      });
    },
    getTemplateContent(print_template_local_id, index) {
      // 自定义打印内容
      getTemplateContentReq({
        order_id: this.order_id,
        print_template_local_id,
        print_bill_type: this.print_bill_type,
        goods_number_ids: this.goods_number_ids,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code === 1) {
          this.$set(this.htmlNodeObj, index, res.data);
        }
      });
    },
    getPrintList() {
      this.loading = true;
      this.htmlNodeObj = {};
      getPrintListReq({
        page: this.page,
        limit: this.limit,
        type: this.type,
      }).then((res) => {
        if (res.code === 1) {
          res.data.list.forEach((item, index) => {
            item.index = index;
          });
          this.printListData = res.data;
          this.printListData.list.forEach((item, index) => {
            item.index = index;
            // this.getTemplateContent(item.print_template_local_id, index);
          });
          this.loading = false;
        }
      });
    },
    handleCoustomPrint() {},
    handleCurrentChange(page) {
      this.page = page;
      this.getPrintList();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
