import { post } from '@/utils/request';

// 自定义打印模板-货品字段列表
const getCustomTemplateFieldsReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getCustomTemplateFields',
  data: {
    ...params,
  },
});

// 获取模板类型
const getCustomTemplateTypeReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getCustomTemplateType',
  data: {
    ...params,
  },
});

// 增加自定义模板
const createCustomTemplateReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/createCustomTemplate',
  data: {
    ...params,
  },
});

// 自定义打印模板列表
const getCustomTemplateListReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getCustomTemplateList',
  data: {
    ...params,
  },
});

// 创建人接口
const getEmployeeReq = (params) => post({
  url: '/store/common.select/getEmployee',
  data: {
    ...params,
  },
});

// 获取打印模板内容
const getTemplateContentReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getCustomTemplateContent',
  data: {
    ...params,
  },
});

// 删除模板
const templateDelReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/delCustomTemplate',
  data: {
    ...params,
  },
});

// 获取模板内容
const getCustomTemplateInfoReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getCustomTemplateInfo',
  data: {
    ...params,
  },
});

// 更新模板
const updateCustomTemplateReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/updateCustomTemplate',
  data: {
    ...params,
  },
});

// 获取表格数据源
const getTemplateGoodsListReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getTemplateGoodsList',
  data: {
    ...params,
  },
});

// 获取条码类型
const getBarcodeTypeReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getBarcodeType',
  data: {
    ...params,
  },
});

// 获取一维码
const getGoodsBarcodeReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/getGoodsBarcode',
  data: {
    ...params,
  },
});

// 复制模板
const copyCustomTemplateReq = (params) => post({
  url: '/store/goods.PrintLocalTemplate/copyCustomTemplate',
  data: {
    ...params,
  },
});


export {
  copyCustomTemplateReq,
  getGoodsBarcodeReq,
  getBarcodeTypeReq,
  getTemplateGoodsListReq,
  updateCustomTemplateReq,
  getCustomTemplateInfoReq,
  templateDelReq,
  getTemplateContentReq,
  getCustomTemplateFieldsReq,
  getCustomTemplateTypeReq,
  createCustomTemplateReq,
  getCustomTemplateListReq,
  getEmployeeReq,
};
