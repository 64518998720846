import { post } from '@/utils/request';
// 创建模板createPrintTemplate
const createReq = (params) => {
  return post({
    url: '/store/goods.PrintLocalTemplate/createPrintTemplate',
    data: {
      ...params,
    },
  });
};

// 获取列表
const getListReq = (params) => {
  return post({
    url: '/store/goods.PrintLocalTemplate/templateList',
    data: {
      ...params,
    },
  });
};

// 删除模板
const deleReq = (params) => {
  return post({
    url: '/store/goods.PrintLocalTemplate/templateDel',
    data: {
      ...params,
    },
  });
};

// 获取模板内容
const getTemplateContentReq = (params) => {
  return post({
    url: '/store/goods.PrintLocalTemplate/getTemplateContent',
    data: {
      ...params,
    },
  });
};

export {
  getTemplateContentReq,
  createReq,
  getListReq,
  deleReq,
};
