<template>
  <div>
    <div class="table">
      <el-table :loading="loading" :data="printListData.list" stripe height="300">
        <template v-for="item in printRowList">
          <el-table-column
            :key="item.field_alias"
            align="center"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_width"
          >
          </el-table-column>
        </template>
        <el-table-column
          align="center"
          prop="action"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" v-print="'custom' + scope.row.index" :disabled="htmlNodeObj[scope.row.index] ? false : true">打印</el-button>
            <!-- <el-button type="text" @click="handlePrint">控件打印</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-show="false">
      <template v-for="(item, key) in htmlNodeObj">
        <div :key="key" :id="'custom'+ key" v-html="item"></div>
      </template>
    </div>
    <div class="pagina">
      <el-pagination
          background
          :current-page="page"
          @current-change="handleCurrentChange"
          :page-size="limit"
          layout="total, prev, pager, next, jumper"
          :total="printListData.total"
        >
        </el-pagination>
    </div>
  </div>
</template>

<script>
import { getListReq as getPrintListReq, getTemplateContentReq } from '@/api/system/print/list';

export default {
  name: 'Print',
  props: {
    type: Number,
    order_id: Number | String,
    print_bill_type: String,
    goods_number_ids: Array,
  },
  data() {
    return {
      loading: false,
      page: 1,
      limit: 10,
      printListData: {
        list: [],
        total: 0,
      },
      htmlNodeObj: {},
      printRowList: [
        { field_text: "模板名称", field_alias: "template_name" },
        { field_text: "模板类型", field_alias: "type_name" },
        // { field_text: "修改人", field_alias: "index2" },
        // { field_text: "修改时间", field_alias: "index3" },
        { field_text: "创建人", field_alias: "store_user_name" },
        { field_text: "创建时间", field_alias: "create_time" },
      ],
    };
  },
  created() {
    this.getPrintList();
  },
  methods: {
    // 控件打印
    handlePrint() {
      LODOP=getLodop();  
		LODOP.PRINT_INITA(0,0,522,333,"打印控件功能演示_Lodop功能_自定义纸张1");	
		LODOP.SET_PRINT_PAGESIZE(0,290,360,"A4");
		LODOP.ADD_PRINT_HTM(0,0,"100%","100%",document.getElementById('custom0').innerHTML);
	  	LODOP.PREVIEW();
    },
    getTemplateContent(print_template_local_id, index) {
      // 自定义打印内容
      getTemplateContentReq({
        order_id: this.order_id,
        print_template_local_id,
        print_bill_type: this.print_bill_type,
        goods_number_ids: this.goods_number_ids,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$set(this.htmlNodeObj, index, res.data);
          }
        });
    },
    getPrintList() {
      this.loading = true;
      this.htmlNodeObj = {};
      getPrintListReq({
        page: this.page,
        limit: this.limit,
        type: this.type,
      })
        .then((res) => {
          if (res.code === 1) {
            res.data.list.forEach((item, index) => {
              item.index = index;
            });
            this.printListData = res.data;
            this.printListData.list.forEach((item, index) => {
              item.index = index;
              this.getTemplateContent(item.print_template_local_id, index);
            });
            this.loading = false;
            console.log(this.htmlNodeObj);
          }
        });
    },
    handleCoustomPrint() {},
    handleCurrentChange(page) {
      this.page = page;
      this.getPrintList();
    },
  },
};
</script>
